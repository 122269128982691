<template>
  <div class="identity">
    <BaseHeaderBar title="Informasi Identitas" />
    <div class="content">
      <div>
        <img class="ktp-img" v-if="ktpImg" :src="ktpImg" />
        <van-uploader v-else :after-read="afterKtpRead" :preview-image="false" capture="camera">
          <p>KTP</p>
          <img class="upload-icon" src="@/assets/img/identity.png" />
          <img class="identity-camera" src="@/assets/img/identity-camera.png" />
        </van-uploader>
        <van-overlay :show="ktpLoadingInfo.show" @click="ktpLoadingInfo.show = false" :custom-style="{ position: 'absolute' }">
          <van-loading color="#B83AF3" vertical>{{ ktpLoadingInfo.text }}</van-loading>
        </van-overlay>
      </div>
      <div>
        <img class="user-img" v-if="userImg" :src="userImg" />
        <van-uploader v-else :after-read="afterUserRead" :preview-image="false" capture="user">
          <p>Fitur Pengenalan Wajah</p>
          <img class="recognition-icon" src="@/assets/img/recognition.png" />
          <img class="identity-camera" src="@/assets/img/identity-camera.png" />
        </van-uploader>
        <van-overlay :show="ktpLoadingInfo.show" @click="ktpLoadingInfo.show = false" :custom-style="{ position: 'absolute' }">
          <van-loading color="#B83AF3" vertical>{{ userLoadingInfo.text }}</van-loading>
        </van-overlay>
      </div>
    </div>
    <button type="button" class="confirm-btn" @click="onSubmit">Kirim</button>
  </div>
</template>

<script>
import Vue from 'vue'
import { Uploader, Toast, Loading, Overlay } from 'vant'
import axios from 'axios'
import request from '@/utils/request'

Vue.use(Uploader)
Vue.use(Toast)
Vue.use(Overlay)
Vue.use(Loading)

function randomString (len = 32) {
  const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  const maxPos = chars.length
  let pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

export default {
  name: 'Identity',
  data () {
    return {
      imgSrc: '',
      ktpImg: '',
      ktpLoadingInfo: {
        show: false,
        text: 'Proses Unggah'
      },
      userImg: '',
      userLoadingInfo: {
        show: false,
        text: 'Proses Unggah'
      }
    }
  },
  created () {
    this.getIdentify()
    this.uploadPromise = this.getUploadParams()
  },
  // mounted () {
  //   this.takePhoto()
  // },
  methods: {
    getIdentify () {
      request.post('iddrentityInfoPage')
        .then(res => {
          this.userImg = res.usdrerLiving_imgurl
        })
    },
    takePhoto () {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      const video = document.getElementById('video')
      const mediaConstraints = {
        audio: false,
        video: {
          width: { ideal: 375 },
          height: { ideal: 250 },
          frameRate: {
            ideal: 30,
            max: 10
          },
          facingMode: 'user'
        }
      }
      const errBack = function (error) {
        console.log('Video capture error: ', error.code)
      }
      setInterval(() => {
        context.drawImage(video, 0, 0, 375, 250)
        this.imgSrc = canvas.toDataURL('image/png')
      }, 3000)

      const getUserMedia = this.isSupportUserMedia()
      if (getUserMedia) {
        getUserMedia(mediaConstraints, function (stream) {
          video.srcObject = stream
          video.play()
        }, errBack)
      }
    },
    isSupportUserMedia () {
      return navigator.mediaDevices.getUserMedia || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia
    },
    getUploadParams () {
      return request.post('updrloadImgToken')
    },
    uploadImg (file) {
      return new Promise((resolve, reject) => {
        this.uploadPromise.then(res => {
          const key = Date.now() + randomString(10) + file.file.name
          const formData = new FormData()
          formData.append('OSSAccessKeyId', 'LTAI5tD9wrLfvn8sEpRLfpbw')
          formData.append('key', key)
          formData.append('policy', res.podrlicy)
          formData.append('signature', res.sidrgnature)
          formData.append('file', file.file)
          formData.append('success_action_status', 200)
          axios.post('https://inphotos.oss-ap-southeast-1.aliyuncs.com', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(() => resolve(`https://inphotos.oss-ap-southeast-1.aliyuncs.com/${key}`))
            .catch(error => {
              Toast('Unggal Gagal')
              reject(error)
            })
        }).catch(error => {
          Toast('Unggal Gagal')
          reject(error)
        })
      })
    },
    afterKtpRead (file) {
      this.ktpLoadingInfo.show = true
      this.uploadImg(file).then(res => {
        this.ktpImg = res
      }).finally(() => {
        this.ktpLoadingInfo.show = false
      })
    },
    afterUserRead (file) {
      this.userLoadingInfo.show = true
      this.uploadImg(file).then(res => {
        this.userImg = res
      }).finally(() => {
        this.userLoadingInfo.show = false
      })
    },
    validate () {
      if (!this.ktpImg) {
        Toast('Silakan foto KTP')
        return false
      }
      if (!this.userImg) {
        Toast('Silakan foto selfie')
        return false
      }
      return true
    },
    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      const postData = {
        phdrotoList: JSON.stringify([{
          phdrotoUrl: this.ktpImg,
          phdrotoType: '1'
        }, {
          phdrotoUrl: this.userImg,
          phdrotoType: '2'
        }])
      }
      request.post('iddrentityInfosubmit', postData)
        .then(() => {
          Toast('Pembaruan infomasi identitas berhasil')
          this.$router.back()
        })
        .finally(() => {
          this.flag = false
        })
    }
  }
}
</script>

<style scoped lang="less">
.identity {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  padding-left: 40px;
  padding-right: 40px;

  & > div {
    display: flex;
    justify-content: center;
    position: relative;
    height: 472px;
    margin-top: 24px;
    border-radius: 12px;
    box-shadow: inset 0 1px 0 0 rgba(253,248,241,.32);
    background-color: #FFF;
    overflow: auto;

    .upload-icon, .recognition-icon {
      width: 602px;
      height: 358px;
      margin: 36px auto 6px;
    }

    .identity-camera {
      position: absolute;
      left: 50%;
      bottom: -21px;
      width: 89px;
      height: 68px;
      transform: translateX(-50%);
    }

    p {
      line-height: 30px;
      font-size: 28px;
      font-weight: 500;
      color: #A6A8AA;
      text-align: center;
    }

    /deep/ .van-loading {
      position: absolute;
    }
  }

  .ktp-img, .user-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.confirm-btn {
  .submit-btn;
  width: 670px;
  margin-top: 48px;
}
</style>
